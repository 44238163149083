import React from 'react';
import Layout from "../components/layout";

const PageRecruit = ({ location, serverData }) => {

  return (
    <Layout location={location}>
      This is a test
    </Layout>
  );
};

export default PageRecruit;

export async function getServerData() {
  return {
    status: 200,
    headers: {},
    props: {}
  }
}